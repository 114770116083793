.token_balance_container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  width: 100%;
  color: #393939;
}

.button_container {
  width: 100%;
  align-items: center;
  gap: 15px;
  display: flex;
}

.balance_area {
  width: 100%;
  margin: 10px;
  display: flex;
  grid-gap: 10px;
  flex-wrap: wrap;
}

.list_Of_address {
  display: block;
  width: 100%;
}

.list_item_address {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 15px;
  gap: 10px;
  border-bottom: 1px solid #ccc;
  color: #757575;
}

.button {
  opacity: 0.7;
  width: 100%;
  background-color: #393939;
  color: #09bc8a;
  border: none;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}

.button:disabled {
  cursor: not-allowed;
}

.button,
.button:hover {
  background-color: #393939;
  color: #09bc8a;
}
