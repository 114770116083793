.toggle_container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 300px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 28.42px;
  border-radius: 31.09px;
  border: 1.15px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 2px;
  bottom: 2px;
  top: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #09bc8a;
}

input:checked + .slider:before {
  transform: translateX(38px);
}

.slider.round {
  border-radius: 34px;
}

.label {
  font-size: 14px;
  color: #393939;
}
