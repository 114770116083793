.inputSelectContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ebedf414;
  background: #d9d9d90a;
  border-radius: 5px;
  padding: 10px;
  position: relative; /* Needed for absolute positioning of the dropdown */
  width: 60%;
}

.input {
  width: 100%;
  border: none;
  outline: none;
  height: 100%;
  background-color: transparent;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure the dropdown is above other elements */
  margin-top: 4px;
  color: #757575;
}

.dropdownOption {
  padding: 10px;
  cursor: pointer;
}

.dropdownOption:hover {
  background-color: #f0f0f0;
}
