.enhanced-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 20px auto;
}

.csv-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.search-bar {
  width: 100%;
}

.mt-3 {
  margin-top: 1rem;
}

.token_balance_container {
  display: flex;
  align-items: center;
  /* margin-bottom: 8px; */
  justify-content: space-between;
  width: 100%;
  color: #393939;
}

.button_container {
  width: 100%;
  align-items: center;
  gap: 15px;
  display: flex;
}

.upload-csv-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.upload-csv-label {
  padding: 10px;
  border: 1px solid #ccc;
  color: #393939;
  text-transform: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  /* background-color: #757575; */
}

.button {
  opacity: 0.7;
  width: 100%;
  background-color: #393939;
  color: #09bc8a;
  border: none;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}

.button:disabled {
  cursor: not-allowed;
}

.button,
.button:hover {
  background-color: #393939;
  color: #09bc8a;
}
