body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.section {
  padding: 50px 0;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.m-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.faq-icon {
  transition: all 0.3s linear;
}

.rotate {
  transform: rotate(-180deg);
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #393939;
}

.faq {
 
}

.collapse {
  /* border: 1px solid #ddd; */
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 15px;
  width: 100%;
}

.collapse-trigger {
  cursor: pointer;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #757575;
}

.questionTitle-label {
  font-size: 18px;
}

.icon {
  font-size: 24px;
}

.collapse-content {
  padding: 15px;
  display: none;
}

.isExpanded .collapse-content {
  display: block;
}

.video {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .m-container {
    padding: 10px;
  }

  .collapse-trigger {
    padding: 10px;
  }

  .questionTitle-label {
    font-size: 16px;
  }
}
