.upload-csv-label {
  padding: 10px;
  border: 1px solid #ccc;
  color: #393939;
  text-transform: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  /* background-color: #757575; */
}

.upload-csv-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

