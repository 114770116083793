.emptyccardheight {
  min-height: 250px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.goog-te-combo {
  border: none;
  color: #fefefe;
  background-color: transparent;
}

.err-msg {
  background-color: red;
  text-align: center;
  color: #fefefe;
  font-size: 0.8rem;
  border-radius: 8px;
  padding: 10px;
  margin: 0 auto;
}

.approved {
  color: green;
}

.pending {
  color: #ff9b44;
}

.failed {
  color: red;
}
.copy {
  cursor: pointer;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.uploads {
  display: inline-flex;
  text-align: center;
}

.inputfile + label {
  cursor: pointer;
  display: inline-block;
  padding: 15px;
  margin-top: 15px;
  border-radius: 8px;
  background: rgba(169, 186, 243, 0.15);
  border: 1px solid #a9baf3;
  font-size: 0.5rem;
  line-height: 18px;
  color: #344684;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #ffffff;
  color: #344684;
}
.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.previewImg {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  overflow: hidden;
}

.businessImg {
  width: 100%;
}

.center-content {
  margin: 0 auto;
  margin-top: 20px;
}

/* Add Animation */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
